import './App.css';
import { MainPage } from './components/main_page';
import { WalletConnectBar } from './components/wallet_connect_bar';
import { Buffer } from "buffer";

if (!window.Buffer) {
  window.Buffer = Buffer;
}

function App() {
  return (
    <>
      <MainPage/>
      {/*<WalletConnectBar/>*/}
    </>
  );
}

export default App;
