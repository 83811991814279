
import './main_page.css'

export function MainPage() {
    return (
        <div className='mp-root'>
            <div className='mp-container'>
                <div className='mp-center'>
                    <img className='mp-image' src='./mp.png' />
                </div>
            </div>
        </div>
    );
}